.contact-container {
    /* border: solid 1px black; */
    display: flex;
    margin-top: 100px;
    /* height: 100vh; */
    justify-content: space-between;
    flex-wrap: wrap;
}

.contact-text-container {
    /* border: solid 1px black; */
    /* width: 50%; */
    flex: 1;
    /* margin-left: 40px; */
}

.callout-container {
    /* border: solid 1px black; */
    font-family: "Roboto";
    font-size: 5.5vmax;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.callout-container div {
    /* border: solid 1px black; */
    display: inline;
}

.callout-container #lets {
    margin-left: 50%;
}

.callout-container #create {
    margin-left: 15%;
}

.callout-container #create::first-letter {
    color: #ff4d00;
    width: 50%;
}

.callout-container #together {
    margin-left: 30%;
}

.contact-info-container {
    /* border: solid 1px black; */
    font-family: "Roboto";
    font-size: 1.5vmax;
    margin-top: 2vmax;
    margin-bottom: 2vmax;
    margin-left: 20%;
}

.contact-info-container div {
    /* border: solid 1px black; */
}

.contact-info-container span {
    margin-left: 10px;
}