.footer {
    position: relative;
    width: 100%;
    bottom: 0;
    /* padding-top: 3em; */
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border: 1px solid black; */
}

.footer-top-line {
    border-bottom: 1px solid #A0A0A0;
    width: 90%;
    left: 0;
    right: 0;
    margin: auto;
}

.footer-content-container {
    display: flex;
    /* border: 1px solid black; */
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    left: 0;
    right: 0;
    margin: auto;
}

.footer-content-container span {
    color: #A0A0A0;
    font-size: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.footer-content-container .social-media {
    /* border: 1px solid black; */
    width: 200px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-content-container .social-media svg {
    cursor: pointer;
}