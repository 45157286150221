.contact-form-container {
    /* border: 1px solid black; */
    margin-right: 5%;
    margin-left: 5%;;
    /* width: 100%; */
    flex: 1;
    height: 600px;
    max-width: 750px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.input-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.input-container input {
    border: 1px solid #A0A0A0;
    width: 49%;
    height: 60px;
    font-size: 1.5vmax;
    font-family: "Roboto";
}

.contact-form-container textarea {
    border: 1px solid #A0A0A0;
    font-size: 1.5vmax;
    font-family: "Roboto";
    resize: none;
    width: 100%;
    height: 450px;
    left: 0;
    right: 0;
    margin: auto;
}

.contact-form-container button {
    cursor: pointer;
    border: 1px solid #A0A0A0;
    background-color: #fff;
    width: 150px;
    height: 50px;
    font-family: "Roboto";
    font-size: 25px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: 0.1s;
}

.contact-form-container button:hover::first-letter{
    color: #ff4d00;
}

.contact-form-container button:hover {
    border: 1px solid #000;
}