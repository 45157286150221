.homepage-card {
    /* border: solid 1px black; */
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 50px #ccc;
    border-radius: 20px;
    min-height: 70vh;
}

.homepage-card h1 {
    /* border: solid 1px black; */
    text-align: center;
    font-family: "Roboto";
}

.homepage-card p {
    /* border: solid 1px black; */
    text-align: center;
    font-family: "Roboto";
    font-size: 30px;
    margin: 15px;
}

.card-icon-container {
    /* border: solid 1px black; */
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}