.service-section-container {
    /* border: solid 1px black; */
    /* opacity: 0; */
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 100px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

/* .service-section-container.inactive {
    opacity: 0;
} */

.service-section-img-container {
    width: 60%;
}

.service-section-img-container.inactive {
    opacity: 0;
}

.service-section-img-container img {
    width: 100%;
    box-shadow: 0 0 50px #ccc;
}

.service-section-text-container {
    /* border: solid 1px black; */
    font-family: "Roboto";
    font-weight: bold;
    text-align: center;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.service-section-text-container.inactive {
    opacity: 0;
}

.service-section-text-container h3 {
    /* border: solid 1px black; */
    font-size: 4vmax;
}

.service-section-text-container p {
    /* border: solid 1px black; */
    font-size: 2vmax;
}

.service-section-text-container h3::first-letter {
    color: #ff4d00;
}

@media only screen 
  and (max-device-width: 400px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .service-section-container {
        flex-direction: column;
    }

    .service-section-img-container {
        width: 100%;
    }

    .service-section-text-container {
        width: 100%;
    }
}