.nav-container {
	background-color: #fff;
	width: 240px;
	height: 100%;
	position: fixed;
	right: 0px;
	top: 0px;
    box-shadow: 0 0 50px #ccc;
	transition: 0.5s;
	z-index: 2;
	overflow: hidden;
}

.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: blue; */
	position: absolute;
	width: 100%;
	flex-direction: column;
	top: 100px;
	overflow: visible;
	height: 300px;
	/* border: 1px solid #0f0f0f; */
}

.menu a {
	font-family: Segoe UI;
	font-style: normal;
	font-weight: bold;
	font-size: 25px;
	color:#000;
	width: 100%;
	height: 30%;
	transition: 0.5s;
	padding-top: 20px;
	padding-bottom: 20px;
	text-align: center;
    /* border: solid 1px black; */
}

.menu a span {
    border-bottom: solid 3px #ff4D00;
    width: 0%;
    display: block;
    margin-left: 30%;
    transition: 0.5s;
}

.menu a:hover span{
	width: 40%;
}

.close-icon {
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
	transition: 0.3s;
}

.close-icon:hover {
	transform: scale(1.5);
}