/* Landing page styles */
.landing-container {
    /* border: 1px solid black; */
    width: 100%;
    height: 100vh;
    left: 0;
    right: 0;
    margin: auto;
    overflow: hidden;
}

.landing-container #c1, .landing-container #c2 {
    font-family: 'Roboto';
    font-size: 55vmax;
    position: absolute;
    /* border: solid 1px black; */
}

.landing-container #c1 {
    top: -90px;
    margin-top: 0;
    left: 13%;
    height: 800px;
    line-height: 800px;
    overflow: hidden;
}

.landing-container #c2 {
    bottom: -90px;
    margin-top: 0;
    right: 13%;
    height: 800px;
    line-height: 800px;
    overflow: hidden;
}

.landing-container #digital-lab {
    /* border: 1px solid black; */
    position: absolute;
    font-family: 'Roboto';
    font-size: 5.5vmax;
    left: 8%;
    bottom: 80px;
    width: 26%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.landing-container #digital-lab span:first-child {
    color: #ff4D00;
} 

/* about page styles */
.about-container {
    /* border: 1px solid black; */
    width: 100%;
    /* height: 100vh; */
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.about-container .sentence {
    /* border: 1px solid black; */
    font-family: "Roboto";
    font-size: 3vmax;
    /* font-weight: bold; */
    position: relative;
    margin-top: 60px;
    text-align: center;
}

.about-container .cards {
    /* border: solid 1px black; */
    width: 90%;
    height: 60%;
    position: relative;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 80px;
    /* display: flex;
    flex-direction: row;
    justify-content: space-around; */
    display: grid;
    grid-gap: 20px;
    grid-auto-rows: 1fr;
    grid-auto-columns: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(1fr, 1fr));
}