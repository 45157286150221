/* Menu icon */
.menu-icon-container {
	display: inline-block;
	cursor: pointer;
	position: fixed;
	right: 20px;
	top: 10px;
	z-index: 1;
}

.bar1, .bar2, .bar3 {
	width: 35px;
	height: 4px;
	background-color: #000;
	margin: 10px 0;
	transition: 0.3s;
}

/* Rotate first bar */
.menu-icon-container:hover .bar1 {
	-webkit-transform: rotate(-30deg) translate(-10px, 2px) ;
	transform: rotate(-30deg) translate(-10px, 2px) ;
}
  
/* Fade out the second bar */
.menu-icon-container:hover:hover .bar2 {
	-webkit-transform: rotate(90deg) translate(0px, -9px);
	transform: rotate(90deg) translate(0px, -9px);
}
  
/* Rotate last bar */
.menu-icon-container:hover:hover .bar3 {
	-webkit-transform: rotate(30deg) translate(-10px, -2px) ;
	transform: rotate(30deg) translate(-10px, -2px) ;
}