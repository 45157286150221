.rotating-container {
    /* border: 1px solid black; */
    font-size: 4vmax;
    font-family: 'Roboto';
    font-weight: bold;
    color: #ff4D00;
    height: 60px;
}

.rotating-container p {
    /* border: 1px solid black; */
    position: relative;
    width: 90%;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
  
.letter {
    display: inline-block;
    position: relative;
    /* float: left; */
    transform: translateZ(60px);
    transform-origin: 50% 50% 60px;
}

.out {
    transform: rotateX(90deg);
    transition: transform 0.42s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.behind {
    transform: rotateX(-90deg);
}

.in {
    transform: rotateX(0deg);
    transition: transform 0.48s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media only screen 
and (max-device-width: 830px) 
and (-webkit-min-device-pixel-ratio: 2) {
    .rotating-container {
    top: 250px;
    /* border: solid 1px white; */
    right: 0;
    width: 80%;
    }
}