@charset "utf-8";

/* Style reset */

article, aside, blockquote, body, button, code, dd, details, 
div, dl, dt, fieldset, figcaption, figure, footer, form, h1, 
h2, h3, h4, h5, h6, header, hgroup, hr, input, legend, li, 
menu, nav, ol, p, pre, section, td, textarea, th, ul {
    padding: 0;
    margin: 0;
}

body {
    /* background-color: #000; */
}

ul, ol, li {
    list-style: none;
}

a {
    text-decoration: none;
    color: #ffffff;
}

i, em {
    font-style: normal;
}

input, textarea, button, select, a {
    outline: none;
    border: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

img {
    border: none;
    vertical-align: middle;
}